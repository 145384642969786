export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'pt',
  fallbackLocale: 'pt',
  messages: {
    pt: {


      //pagina Principal
      years: 'HÁ',
      years_label: 'ANOS',
      trusted_partner: 'SUA PARCEIRA CONFIÁVEL',
      industrial_solutions: 'EM SOLUÇÕES INDUSTRIAIS',
      description_banners: 'Por três décadas, temos sido a escolha confiável para empresas em busca de soluções industriais. Oferecemos inspeções, consultoria e treinamentos especializados. Além disso, fornecemos a plataforma inteligente ',
      description_bannerss: 'para inspeções industriais e soluções de automação, incluindo monitoramento térmico por termografia infravermelha. Nosso compromisso é garantir segurança, confiabilidade e produtividade para nossos clientes.',
      
      mci_titile1:'OBTENHA',
      mci_titile2: 'TODO O POTENCIAL',
      mci_titile3:'NA SUA MANUTENÇÃO',
      mci_titile4: 'DE FORMA SIMPLES E ESTRUTURADA',
      mci_description1: 'Com o método',
      mci_description2: 'da Pred Engenharia, você experimentará uma perspectiva revolucionária na gestão da manutenção. Ao contrário de outros métodos,',
      mci_description3: 'não apenas integra a cadeia de valor da manutenção, mas também oferece atribuições e resultados claros e auditáveis. Prepare-se para uma transformação completa na forma como você enxerga e gerencia a manutenção .',
      mci_learn_more: 'Saiba mais sobre o método',
      
      pred_engineering_description: 'A PRED ENGENHARIA visa atender às necessidades das indústrias, na área de manutenção preditiva industrial, através de prestação de serviços e consultorias. Atua também na pesquisa e desenvolvimento de dispositivos, equipamentos e sistemas voltados para área de manutenção preditiva, instrumentação e controle.',
      
      our_journey:'UM POUCO DA NOSSA CAMINHADA',

      services: 'NOSSOS SERVIÇOS',
      engineering: 'SERVIÇOS DE ENGENHARIA DE MANUTENÇÃO & CONFIABILIDADE',
      engineering_desc: 'Na Pred Engenharia, oferecemos uma gama completa de serviços de engenharia projetados para otimizar a performance dos seus equipamentos e processos industriais. De diagnósticos precisos a treinamentos especializados, nossa equipe de especialistas está aqui para impulsionar a eficiência, reduzir custos e maximizar a produtividade da sua operação. Descubra como nossos serviços de ponta podem elevar o seu negócio para novos patamares de sucesso.',
      learn_more: 'Saiba mais sobre o serviço',
      Learn_more_about_us: 'Leia mais sobre nós',
      inspection: 'SERVIÇOS TÉCNICOS DE INSPEÇÃO',
      inspection_desc: 'Na Pred Engenharia, oferecemos mais do que simples inspeções; oferecemos uma visão detalhada e abrangente do estado dos seus equipamentos industriais. Nossos serviços técnicos de inspeção vão além do óbvio, utilizando tecnologias avançadas e abordagens inovadoras para revelar os segredos que podem estar escondidos nas entranhas da sua operação. Seja por meio da termografia, análise de vibração, inspeção sensitiva ou outras técnicas especializadas, estamos aqui para fornecer insights valiosos que impulsionam a eficiência, a segurança e a confiabilidade dos seus ativos.',
      s3i: 'SISTEMA INTELIGENTE DE INTERVENÇÃO INDUSTRIAL',
      s3i_desc: 'Convidamos você a explorar uma nova era na gestão industrial. Imagine uma ferramenta que não apenas simplifica, mas também aprimora cada etapa da sua manutenção. Aperte abaixo para mergulhar no mundo do S3i® e descubra como ele pode elevar sua operação a novos patamares de excelência.',
      sft: 'SISTEMA FIXO DE TERMOGRAFIA',
      sft_desc: 'Imagine ter o controle completo das temperaturas críticas em suas operações industriais, mesmo em condições desafiadoras. Com o Sistema Fixo de Termografia (SFT) da Pred Engenharia, essa visão se torna realidade. Quer descobrir como o SFT pode transformar sua abordagem à segurança operacional e eficiência na indústria? Aperte abaixo para saber mais sobre essa ferramenta inteligente de monitoramento termográfico e leve sua operação a um novo patamar de precisão e confiabilidade.',

      know_us: "CONHEÇA-NOS",
      about_description:"A Pred Engenharia é uma provedora de soluções para gestão de ativos e processos, com foco na garantia da segurança, confiabilidade e produtividade das atividades. Atuando na indústria e na área de serviços desde 1993, suas inovadoras soluções são fornecidas ao mercado através de três áreas de negócios: Serviços Técnicos, Tecnologia da Informação e Automação Industrial.",
      connect_social:'Conecte-se conosco nas redes sociais',

      contact_us: "Fale Conosco",
      name: "Nome",
      email: "E-mail",
      phone: "Celular",
      whatsapp: "WhatsApp",
      send: "Enviar",
      name_hint: "Digite seu Nome",
      email_hint: "Digite um e-mail válido",
      phone_hint: "Digite um numero válido. Apenas numeros!",
      whatsapp_hint: "Digite um numero de WhatsApp válido",
      error_message: "Por favor, completa este campo corretamente",
      
      Contact: 'Contato',


      about_us: 'Sobre Nós',
      our_services: 'Nossos Serviços',
      engineering_maintenance_reliability_services: 'Serviços de Engenharia de Manutenção & Confiabilidade',
      technical_inspection_services: 'Serviços Técnicos de Inspeção',
      s3i_system: 'S3I - Sistema Inteligente de Intervenção Industrial',
      sft_system: 'SFT - Sistema Fixo de Termografia',
      blog: 'Blog',
      vip:'Sala Vip',
      intranet:'Intranet',

    // MCI - PAGINA
    conditioned_maintenance1: 'MANUTENÇÃO',
    conditioned_maintenance2: 'CONDICIONADA À INSPEÇÃO',
    discover_mci_method: 'DESCUBRA O MÉTODO MCI®: A REVOLUÇÃO NA MANUTENÇÃO',


    rbm_notice: 'Certifique-se que a RBM® (Rice & Beans Maintenance), está, de fato, implementada antes de investir tempo e energia na RCM® (Reliability Centered maintenance). A gente te explica como:',
      mci_intro: 'Imagine uma abordagem de manutenção que não apenas integra, mas também otimiza toda a cadeia de valores da manutenção,',
      unprecedented_efficiency: 'garantindo eficiência e previsibilidade sem precedentes.',
      mci_offer: 'Isso é exatamente o que o',
      mci_method: 'Método MCI® (Manutenção Condicionada à Inspeção) da Pred Engenharia oferece.',
      mci_cycle_intro: 'No Método MCI®, as manutenções preventivas condicionadas e corretivas planejadas são perfeitamente integradas em um ciclo fechado. Esse ciclo é impulsionado por uma ferramenta inteligente chamada',
      perigometro: 'PERIGÔMETRO®',
      perigometro_role: ', desenvolvida pela Pred Engenharia. O',
      perigometro_benefit: 'avalia com precisão quais intervenções de manutenção são realmente necessárias, proporcionando equilíbrio aos recursos e garantindo disponibilidade e previsibilidade com o menor custo possível.',
      mci_cycle_benefit: 'É nesse ciclo fechado que conseguimos garantir a disponibilidade e previsibilidade com o menor custo possível, pois se trabalha com a visão mínima de 4 semanas à frente (S+4).',
      mci_exceptional: 'Mas o que torna o',
      mci_simplicity: 'Método MCI® verdadeiramente excepcional é sua simplicidade.',
      mci_philosophy_intro: 'Baseado na filosofia de',
      mci_philosophy: '"o simples é o máximo da sofisticação",',
      mci_philosophy_detail: 'todas as ações que sustentam o método são simples e diretas. No entanto, essa simplicidade é a chave para sua solidez e previsibilidade, proporcionando uma gestão de manutenção verdadeiramente eficaz e confiável.',

      mci_series_youtube: 'SÉRIE MCI® NO YOUTUBE:',
      elevate_maintenance: 'ELEVE SUA MANUTENÇÃO A UM NOVO PATAMAR',
      maximize_availability: 'Você está realmente maximizando a disponibilidade dos equipamentos da sua planta? Como anda a sua produtividade? E o controle de custos e eficiência das rotinas de manutenção?',
      exclusive_playlist_intro: 'Em nossa playlist exclusiva,',
      ozorio_rezende: 'Ozório Rezende, Assessor Técnico da Pred Engenharia,',
      deep_dive: 'mergulha profundamente no universo da manutenção industrial.',
      video_series: 'Prepare-se para uma série de vídeos que vão transformar a sua abordagem à manutenção. Esteja pronto para uma jornada de aprendizado e aprimoramento que levará sua manutenção a um novo patamar!',
    
      main_content: 'Nossas soluções inovadoras são oferecidas através de três áreas principais:',
      main_areas: 'Serviços Técnicos, Tecnologia da Informação e Automação Industrial.',
      intro_paragraph: 'Cada uma de nossas áreas representa pilares fundamentais que nos permitem oferecer uma ampla gama de soluções e expertise, atendendo eficazmente às diversas necessidades de nossos clientes.',
      technical_services_area: 'Na área de Serviços Técnicos,',
      technical_services_description: 'oferecemos inspeção preditiva, consultoria em engenharia de manutenção e treinamento técnico.',
      it_area: 'Em Tecnologia da Informação,',
      it_description: 'apresentamos o S3i (Sistema Inteligente de Inspeções Industriais), uma plataforma pioneira que utiliza dispositivos móveis de forma rastreável, inteligente e integrada com sistemas como IBM Maximo, Infor, Oracle JDE, SAP e Totvs, desde 2002.',
      industrial_automation_area: 'Na área de Automação Industrial,',
      industrial_automation_description: 'fornecemos soluções de monitoramento térmico por termografia infravermelha para aprimorar a segurança, qualidade e eficiência em processos críticos.',
    
      specialized_solutions: 'Especializada em soluções de confiabilidade e produtividade para áreas de O&M',
      pred_engineering: 'A Pred Engenharia é especializada em oferecer soluções para a gestão eficiente de ativos e processos. Desde 1993, trabalhamos para garantir a ',
      om_industry_services: 'segurança, confiabilidade e produtividade das operações e manutenções (O&M) na indústria e em serviços.',
    

      service_image: "/services4.webp",
      sft_title: "SISTEMA FIXO DE TERMOGRAFIA",
      sft_solution_description: "Uma solução da Pred acompanhada de engenharia de aplicação e ferramentas específicas",
      sft_subtitle: "SISTEMA FIXO POR TERMOGRAFIA",
      sft_reliable_ally_title: "SFT: Seu Aliado Confiável para Controle Térmico na Indústria",
      sft_reliable_ally_description: "Na indústria, muitas anormalidades ou acidentes operacionais poderiam ser facilmente evitados ou amenizado os seus impactos, se as operações fossem visualizadas em tempo real e integral. Inconvenientes como baixa luminosidade, mal tempo e condições operacionais adversas, dificultam a visualização das operações e consequentemente ampliam a probabilidade da ocorrência de um acidente ou da perda do controle de um processo.",
      sft_thermal_imaging_description_1: "A termografia por infravermelho é o método mais confiável e preciso no acompanhamento contínuo de operações onde a temperatura é uma das variáveis do processo. As câmeras termográficas utilizadas pela Pred Engenharia, possuem lentes com zoom contínuo oferecendo uma solução singular de rastreamento e medição de temperaturas em diferentes distancias. A lente de zoom é totalmente controlada por software especialista. Os sistemas fixos de termografia (SFT), fornecidos pela Pred Engenharia, são customizados para atender as mais diversas aplicações de análises e avaliações sistêmicas de irregularidades quantitativas e/ou qualitativas. Sua aplicação se faz presente em processos relacionados à visão de apoio e em controle de processos.",
      sft_unique_solution_description: "Único no mercado que realiza auditoria da qualidade e efetividade de todos os tipos de inspeção em tempo real por meio de uma metodologia própria.",
      sft_image_1: "/sft/1.webp",
      sft_image_2: "/sft/2.webp",
      support_vision: "Visão de Apoio",
      support_vision_description: "Com ênfase na segurança operacional em equipamentos e processos, esse tipo de aplicação, classificada de baixo custo e de alto valor agregado, é essencialmente qualitativa (distribuição térmica) e possibilita supervisão em tempo real e integral.",
      process_control: "Controle de Processos",
      process_control_description_1: "A termografia aplicada a controle de processos visa assegurar qualidade, produtividade e/ou segurança. As aplicações são qualitativas e também quantitativas em operações em tempo real e integral.",
      process_control_description_2: "As soluções da Pred, aplicadas a controle de processos são sempre acompanhadas de engenharia de aplicação e ferramentas específicas. O Portal SFT é uma dessas ferramentas. É um sistema inteligente para a supervisão e monitoração de tendências termográficas de equipamentos e processos nas mais diversas aplicações industriais. O Portal SFT é totalmente configurável e apto a trabalhar em nuvem.",
      pred_solutions_description: "A termografia aplicada a controle de processos visa assegurar qualidade, produtividade e/ou segurança. As aplicações são qualitativas e também quantitativas em operações em tempo real e integral.",
      infrared_thermography_description: "A termografia por infravermelho é o método mais confiável e preciso no acompanhamento contínuo de operações em locais com condições adversas para visualização.",
      sft_image_3: "/sft/3.webp",
      sft_image_4: "/sft/4.webp",
      why_choose_sft: "Por que escolher o SFT? Aqui estão alguns benefícios-chave:",
      precision_reliability: "Precisão e Confiabilidade",
      precision_reliability_description: "A termografia por infravermelho é o método mais confiável para monitoramento contínuo de operações onde a temperatura é crítica. Nossas câmeras termográficas possuem lentes com zoom contínuo, oferecendo medição precisa em diferentes distâncias.",
      sft_image_5: "/sft/5.webp",
      specialized_engineering: "Engenharia Especializada",
      specialized_engineering_description: "Nossas soluções são acompanhadas de engenharia de aplicação e ferramentas específicas, incluindo o Portal SFT, um sistema inteligente para monitorar tendências termográficas em nuvem.",
      sft_potential: "Obtenha o potencial da sua operação com o SFT da Pred Engenharia. Conte com a termografia por infravermelho para manter suas operações seguras e eficientes, mesmo nas condições mais adversas.",
      sft_more_than_camera_description:'O SFT é muito mais do que apenas uma câmera termográfica. É uma solução completa, acompanhada de engenharia de aplicação e ferramentas específicas para garantir o máximo desempenho em diversas aplicações industriais.',

      service_image3: "/services3.webp",
      s3i_title: "SISTEMA INTELIGENTE DE INTERVENÇÃO INDUSTRIAL",
      solution_description: "Uma solução de alto valor agregado deve ser mais do que um simples e-paper",
      subtitle: "MOBILIDADE PARA MANUTENÇÃO",
      image_1: "/s3i/1.webp",
      description_1: "Já imaginou otimizar os tempos de inspeção com inteligência e intuição, enquanto aumenta sua produtividade com segurança, precisão das informações e controle de desempenho das atividades da equipe?",
      description_2: "O software S3i é uma ferramenta que adere às rotinas operacionais utilizando a mobilidade corporativa, garantindo rastreabilidade, inteligência e produtividade às atividades de inspeção, teste e auditoria.",
      description_3: "Único no mercado, realiza auditorias de qualidade e eficácia de todos os tipos de inspeções em tempo real por meio de uma metodologia própria.",
      traceability_intelligence_title: "Rastreabilidade e Inteligência para IBM Maximo, Infor, Oracle JDE, SAP e Totvs",
      traceability_intelligence_description_1: "O S3i se destaca pela capacidade de integrar a manutenção com todos os outros sistemas de informação utilizados nas empresas. Possui uma interface fácil de usar que permite o gerenciamento da manutenção preditiva e a transferência das informações de inspeção para um banco de dados ERP. Assim, são processadas, gerando novas informações que permitem a tomada de decisão sobre a necessidade ou não de reparar o equipamento inspecionado.",
      traceability_intelligence_description_2: "Como sistema autônomo, quando integrado aos sistemas de gestão utilizados pela empresa, o S3i permite maior controle sobre o cumprimento das atividades, tornando o sistema de gestão de manutenção adotado pelas empresas ainda mais robusto.",
      image_2: "/s3i/2.webp",
      exclusivities_title: "Exclusividades do Software S3i:",
      exclusivity_1: "Operações de inspeção rastreáveis e inteligentes com peculiaridades essenciais para a gestão de ativos;",
      exclusivity_2: "Utilização de códigos de barras, 2D ou RFID como posicionador do inspetor em relação às partes do Ativo a ser inspecionado;",
      exclusivity_3: "Utilização de catálogos inteligentes para o registro e tratamento de anomalias (FMEA) em campo;",
      exclusivity_4: "Utilização de ferramentas de controle de tempos e movimentos como forma de monitorar a qualidade das inspeções;",
      exclusivity_5: "Utilização de reinspeções pós–reparo como forma de validar a eficácia das manutenções corretivas programadas;",
      exclusivity_6: "Utilização de recurso que permite o registro, tratamento de falha e encerramento simultâneo de anomalias eliminadas no ato da detecção;",
      exclusivity_7: "Avaliações sistêmicas do risco de falha (Perigômetro) sobre cada anomalia, utilizando o conceito do GUT, permitindo assim filtrar as que de fato devem gerar Nota/Ordem no ERP/EAM.",
      image_3: "/s3i/3.webp",

      main_content1: 'SERVIÇOS TÉCNICOS DE INSPEÇÃO',
      heading_termografia: 'Desvendando o Invisível com Termografia',
      termografia_description: 'Evitar falhas e acidentes em instalações industriais é mais do que uma prioridade; é uma necessidade. Na Pred Engenharia, oferecemos serviços especializados de inspeção utilizando a tecnologia de termografia para proporcionar uma visão clara do invisível.',
      termografia_technique: 'A termografia é uma técnica poderosa que nos permite mapear corpos ou regiões, identificando variações de temperatura que podem indicar potenciais problemas.',
      termografia_market: 'Atuamos no mercado de Termografia Industrial desde 1993, fornecendo serviços e produtos de alta qualidade em todo o Brasil e em outros países.',
      termografia_norms: 'Nossos serviços não apenas atendem às recomendações da norma técnica ABNT NBR 15763.2009, mas também são valorizados pelas seguradoras, que reconhecem a termografia como prática essencial para avaliações precisas de riscos.',
      termografia_trust: 'Confie na Pred Engenharia para garantir a integridade e o desempenho de seus equipamentos e instalações industriais. Agende uma inspeção termográfica hoje mesmo e descubra uma nova perspectiva sobre a segurança e eficiência de sua operação.',
      heading_vibration_analysis: 'Análise de Vibração: Antecipando Anomalias para Manutenção Preventiva',
      vibration_analysis_description: 'Na Pred Engenharia, oferecemos serviços de análise de vibração, uma técnica fundamental para a detecção precoce e diagnóstico de anomalias em componentes móveis de equipamentos industriais.',
      vibration_analysis_technique: 'Utilizando a condição operacional real do equipamento, nossa análise de vibração é baseada em medições periódicas ou contínuas de parâmetros significativos. Isso nos permite antecipar falhas, evitar paradas inesperadas e substituições desnecessárias de peças, garantindo assim a eficiência contínua e produtividade de sua operação.',
      heading_ultrasound_inspection: 'Inspeção por Ultrassom: Detecção Precisa de Defeitos Mecânicos e Vazamentos',
      ultrasound_inspection_description: 'A inspeção por ultrassom é outra ferramenta valiosa que oferecemos para garantir a integridade de seus equipamentos e instalações industriais.',
      ultrasound_inspection_technique: 'Essa técnica permite a detecção e análise de defeitos mecânicos em máquinas de baixa velocidade, instalações elétricas, vazamentos de fluidos gasosos e muito mais.',
      ultrasound_inspection_trust: 'Confie na Pred Engenharia para manter seus equipamentos em perfeitas condições de funcionamento e evitar problemas antes que ocorram. Agende hoje mesmo uma análise de vibração ou inspeção por ultrassom e proteja o futuro de sua operação.',
      heading_sensitive_inspection: 'Inspeção Sensitiva: Um Complemento Essencial para a Manutenção Preditiva',
      sensitive_inspection_description: 'Na Pred Engenharia, entendemos que integrar inspeções sensitivas com as preditivas resulta em insights valiosos e resultados de alto valor agregado para a manutenção industrial.',
      sensitive_inspection_technique: 'A inspeção sensitiva abrange uma ampla gama de modos de falha em equipamentos e instalações industriais. Embora seja baseada principalmente nos sentidos naturais do inspetor, é surpreendentemente eficaz na detecção e diagnóstico de anomalias em estágios intermediários de degradação.',
      sensitive_inspection_advanced_technology: 'Por meio do uso de tecnologia de computação móvel, nossas inspeções sensitivas são elevadas a um novo nível de inteligência e rastreabilidade. Isso significa que cada inspeção não apenas identifica problemas potenciais, mas também fornece dados precisos e acionáveis para orientar ações corretivas e preventivas.',
      sensitive_inspection_trust: 'Confie na Pred Engenharia para fornecer soluções de inspeção abrangentes, integrando abordagens sensitivas e preditivas para garantir a integridade e desempenho contínuo de seus ativos industriais. Entre em contato conosco hoje mesmo para saber mais sobre como podemos ajudar a impulsionar a eficiência e confiabilidade em sua operação.',
    

      services_maintenance_reliability: 'SERVIÇOS DE ENGENHARIA DE MANUTENÇÃO E CONFIABILIDADE',
      efficient_maintenance_systematization: 'SISTEMATIZAÇÃO EFICIENTE DA MANUTENÇÃO: REDUZA SEUS CUSTOS E OTIMIZE CONTROLES',
      efficient_maintenance_text1: 'A sistemática eficiente da manutenção pode gerar uma redução significativa, entre 15% e 30%, nos custos fixos e variáveis.',
      efficient_maintenance_text2: '(Enterprise Resource Planning)',
      efficient_maintenance_text3: 'é hora da manutenção partir para uma nova forma de integrar seus ativos reduzindo custos e melhorando controles.',
      efficient_maintenance_text4: 'Após a era dos sistemas de gestão integrada, como os ERPs (Enterprise Resource Planning), que se concentravam principalmente na gestão contábil, chegou a vez da manutenção adotar uma nova abordagem para integrar seus ativos, reduzindo custos e aprimorando controles.',
      efficient_maintenance_text5:'Este desafio se torna ainda mais crucial para grandes indústrias, onde os custos de manutenção são um ponto crítico. Esses custos representam de 20% a 40% do total dos custos operacionais, totalizando aproximadamente US$ 1 trilhão ao ano globalmente. A boa notícia é que esse valor pode e deve ser reduzido em até um terço com uma gestão eficaz dos ativos operacionais, começando pela implementação racional de sistemas de gestão de manutenção.',
      efficient_maintenance_text6:'Com mais de 30 anos de experiência, a Pred Engenharia oferece consultoria especializada para desenvolver e/ou implementar projetos que incluem:',
      asset_registration: 'Cadastro de ativos (físicos e funcionais);',
      maintenance_plan_creation: 'Criação ou revisão de planos de manutenção com foco em inspeção e lubrificação;',
      mobile_computing_integration: 'Integração de computação móvel para operacionalização e gestão da manutenção, indo além do simples "e-paper";',
      technical_list_creation: 'Criação ou revisão de listas técnicas de sobressalentes e estratégias de estoque;',
      maintenance_staff_optimization: 'Otimização do pessoal de manutenção através de análise de tempos e movimentos das equipes, e capacitação com competências essenciais, utilizando metodologia própria. Isso inclui avaliação de aspectos comportamentais e culturais;',
      erp_migration_support: 'Suporte na migração ou atualização de ERP, fornecendo serviços de preparação dos dados mestres do legado para a nova versão e/ou estrutura de dados.',
      outdated_solutions_warning: 'Não deixe sua empresa presa a soluções ultrapassadas!',
      discover_pred_services: 'Descubra como os serviços da Pred Engenharia podem impulsionar sua operação para novos patamares de eficiência e sucesso!',
      lubrication_engineering: 'ENGENHARIA DE LUBRIFICAÇÃO: OTIMIZE A PERFORMANCE DOS SEUS EQUIPAMENTOS E REDUZA FALHAS',
      lubrication_failures: 'Sabia que pelo menos 50% das falhas em rolamentos e 60% dos problemas em redutores de engrenagens estão ligados à lubrificação?', 
      lubrication_services_intro: 'Na Pred Engenharia, oferecemos cinco serviços essenciais de engenharia para otimizar seus processos de lubrificação e impulsionar a eficiência técnica, gerencial e financeira:',
      maintenance_planning: 'O seu planejamento da manutenção foi feito em campo ou dentro de sala?',
      maintenance_planning_field_or_office: 'Um plano de manutenção bem elaborado, feito por profissionais experientes e no local, não só aumenta a eficácia das operações, mas também reduz o tempo de inatividade não programado, melhorando a produtividade da sua equipe e a confiabilidade dos equipamentos.',
      maintenance_planning_text: 'A Pred Engenharia acredita que um bom plano de manutenção é aquele que é “cumprível”. Para tanto, precisa ser feito em campo e por profissionais experientes, seguindo procedimentos/diretrizes formalmente definidos no diagnóstico da manutenção, sobre as quatro linhas de planejamento acima ilustrado.',
      maintenance_diagnosis: 'DIAGNÓSTICO DA MANUTENÇÃO: REDUZA CUSTOS E OTIMIZE RESULTADOS',
      maintenance_diagnosis_cost_reduction: 'Você sabia que um diagnóstico racional da sua manutenção pode resultar em uma redução significativa de custos, entre 15% e 30%?',
      maintenance_diagnosis_text1: 'Na Pred Engenharia, nossa metodologia para elaboração do diagnóstico da manutenção permite cruzar informações de cinco fontes diferentes. Isso não só fornece uma visão abrangente da situação atual, mas também gera indicadores precisos que direcionam as medidas necessárias para otimizar os resultados da sua manutenção.',
      maintenance_diagnosis_text2: 'Com o diagnóstico da Pred Engenharia, você não apenas identifica áreas de melhoria, mas também toma decisões embasadas em dados concretos, reduzindo seus custos operacionais e aumentando a eficiência dos seus processos de manutenção.',
      team_training: 'CAPACITAÇÃO DE EQUIPES: ELEVE O NÍVEL DE COMPETÊNCIA E EFICIÊNCIA',
      team_training_text1: 'a Pred Engenharia, oferecemos treinamentos fechados, adaptados às suas necessidades específicas, sempre acompanhados de medição de eficiência e eficácia.',
      team_training_text2: 'Além disso, oferecemos nossos treinamentos voltados para a aplicação de técnicas avançadas de manutenção e inspeção, incluindo:',
      team_training_list1: 'Planejamento e Controle da Manutenção: Desde a abordagem RBM até a  RCM, fornecendo as ferramentas necessárias para otimizar seus processos de manutenção',
      team_training_list2:'Aplicações avançadas da termografia em diversas áreas, como inspeções elétricas, revestimentos estruturais, equipamentos rotativos e estáticos, e monitoramento de processos.',
      team_training_list3: 'Capacitação de equipes de lubrificação e gestores, garantindo que suas equipes estejam totalmente preparadas para enfrentar os desafios do dia a dia com confiança e expertise.',
      team_training_text3:'Investir na capacitação de suas equipes não apenas aumenta a eficiência operacional, mas também contribui para a segurança, qualidade e longevidade de seus ativos.',
      in_company_training: 'AUDITORIA DA MANUTENÇÃO: GARANTA EFICIÊNCIA E REDUZA FALHAS',
      specialized_technical_visits: 'Na Pred Engenharia, oferecemos serviços de auditoria da manutenção para garantir que seus planos e rotinas estejam sempre atualizados e alinhados com as melhores práticas do setor.',
      interactive_training_material: 'O grande desafio dos gestores de manutenção é garantir que os processos de análise crítica sobre as rotinas estejam em andamento e que os resultados sejam utilizados para otimizar o plano de manutenção. Com um programa regular de auditorias, você pode detectar a descontinuidade desses processos e tomar medidas para regularizá-los antes que impactem negativamente sua taxa de falhas, interrupções ou custos de manutenção.',
      additional_services: 'Além de identificar áreas de melhoria, nossas auditorias fornecem insights valiosos para aprimorar seus processos de manutenção, aumentando a eficiência operacional e reduzindo os riscos de falhas não planejadas.',
      historia_image1:'1.webp',
      historia_image2:'2.webp',
      historia_image3:'3.webp',
      historia_image4:'4.webp',
      historia_image5:'5.webp',
      historia_image21:'21.webp',
      historia_image31:'31.webp',
      historia_image41:'41.webp',
      historia_image51:'51.webp',
      historia_image61:'61.webp',

      mci_image1:'mci.webp',
      mci_image1_mobile:'mcimobile.webp',
      mci_image2:'mci2.webp',
      mci_image3:'mci3.webp',
      mci_image4:'mciseries.webp',

      service_man_image1:'1.webp',
      service_man_image2:'2.webp',
      service_man_image3:'3.webp',
      service_man_image4:'4.webp',
  
    },
    en: {
      years: 'FOR',
      years_label: 'YEARS',
      trusted_partner: 'YOUR TRUSTED PARTNER',
      industrial_solutions: 'IN INDUSTRIAL SOLUTIONS',
      description_banners: 'For three decades, we have been the trusted choice for companies seeking industrial solutions. We offer inspections, consulting, and specialized training. Additionally, we provide the intelligent platform ',
      description_bannerss: 'for industrial inspections and automation solutions, including thermal monitoring through infrared thermography. Our commitment is to ensure safety, reliability, and productivity for our clients.',
      
      mci_titile1: 'GET',
      mci_titile2: 'ALL THE POTENTIAL',
      mci_titile3: 'IN YOUR MAINTENANCE',
      mci_titile4: 'SIMPLY AND STRUCTURED WAY',
      mci_description1: 'With the method',
      mci_description2: 'from Pred Engenharia, you will experience a revolutionary perspective in maintenance management. Unlike other methods,',
      mci_description3: 'it not only integrates the maintenance value chain but also offers clear and auditable assignments and results. Get ready for a complete transformation in how you see and manage maintenance.',
      mci_learn_more: 'Learn more about the method',
      
      pred_engineering_description: "PRED ENGINEERING aims to meet the needs of industries in the field of industrial predictive maintenance through service provision and consulting. We also engage in research and development of devices, equipment, and systems focused on predictive maintenance, instrumentation, and control.",
      our_journey: "A BIT OF OUR JOURNEY",

      services: 'OUR SERVICES',
      engineering: 'MAINTENANCE & RELIABILITY ENGINEERING SERVICES',
      engineering_desc: 'At Pred Engenharia, we offer a full range of engineering services designed to optimize the performance of your equipment and industrial processes. From precise diagnostics to specialized training, our team of experts is here to boost efficiency, reduce costs, and maximize the productivity of your operations. Discover how our cutting-edge services can elevate your business to new heights of success.',
      learn_more: 'Learn more about the service',
      Learn_more_about_us: 'Learn more about us',
      inspection: 'TECHNICAL INSPECTION SERVICES',
      inspection_desc: 'At Pred Engenharia, we offer more than simple inspections; we provide a detailed and comprehensive view of the state of your industrial equipment. Our technical inspection services go beyond the obvious, utilizing advanced technologies and innovative approaches to reveal the secrets that may be hidden within your operation. Whether through thermography, vibration analysis, sensory inspection, or other specialized techniques, we are here to provide valuable insights that drive efficiency, safety, and reliability of your assets.',
      s3i: 'INTELLIGENT INDUSTRIAL INTERVENTION SYSTEM',
      s3i_desc: 'We invite you to explore a new era in industrial management. Imagine a tool that not only simplifies but also enhances every step of your maintenance. Click below to dive into the world of S3i® and discover how it can elevate your operations to new levels of excellence.',
      sft: 'FIXED THERMOGRAPHY SYSTEM',
      sft_desc: 'Imagine having complete control over critical temperatures in your industrial operations, even in challenging conditions. With Pred Engenharia’s Fixed Thermography System (SFT), this vision becomes a reality. Want to find out how the SFT can transform your approach to operational safety and efficiency in the industry? Click below to learn more about this intelligent thermographic monitoring tool and take your operations to a new level of precision and reliability.',
      
      know_us: "Get to Know Us",
      about_description:"PRED Engineering is a provider of solutions for asset and process management, focused on ensuring the safety, reliability, and productivity of activities. Operating in industry and services since 1993, our innovative solutions are delivered to the market through three business areas: Technical Services, Information Technology, and Industrial Automation.",
      connect_social: "Connect with us on social media",


      contact_us: "Contact Us",
      name: "Name",
      email: "Email",
      phone: "Phone",
      whatsapp: "WhatsApp",
      send: "Send",
      name_hint: "Enter your name",
      email_hint: "Enter a valid email address",
      phone_hint: "Enter a valid phone number",
      whatsapp_hint: "Enter a valid WhatsApp number",
      error_message: "Please fill out this field correctly",
      Contact: 'Contact',


      about_us: 'About Us',
      our_services: 'Our Services',
      engineering_maintenance_reliability_services: 'Engineering Maintenance & Reliability Services',
      technical_inspection_services: 'Technical Inspection Services',
      s3i_system: 'S3I - Intelligent Industrial Intervention System',
      sft_system: 'SFT - Fixed Thermography System',
      blog: 'Blog',
      vip:'Vip Room',
      intranet:'Intranet',
      

      conditioned_maintenance1: 'Maintenance Inspection',
      conditioned_maintenance2: 'Condition-Based',
      discover_mci_method: 'DISCOVER THE MCI® METHOD: THE REVOLUTION IN MAINTENANCE',

      rbm_notice: 'Ensure that RBM® (Rice & Beans Maintenance) is indeed implemented before investing time and energy in RCM® (Reliability Centered Maintenance). We explain how:',
      mci_intro: 'Imagine a maintenance approach that not only integrates but also optimizes the entire maintenance value chain,',
      unprecedented_efficiency: 'guaranteeing unprecedented efficiency and predictability.',
      mci_offer: 'This is exactly what the',
      mci_method: 'MCI® Method (Condition-Based Maintenance Inspection) from Pred Engenharia offers.',
      mci_cycle_intro: 'In the MCI® Method, condition-based preventive maintenance and planned corrective maintenance are perfectly integrated into a closed loop. This cycle is driven by an intelligent tool called',
      perigometro: 'PERIGÔMETRO®',
      perigometro_role: ', developed by Pred Engenharia. The',
      perigometro_benefit: 'accurately evaluates which maintenance interventions are truly necessary, providing balance to resources and ensuring availability and predictability at the lowest possible cost.',
      mci_cycle_benefit: 'It is in this closed loop that we can ensure availability and predictability at the lowest possible cost, as we work with a minimum vision of 4 weeks ahead (S+4).',
      mci_exceptional: 'But what makes the',
      mci_simplicity: 'MCI® Method truly exceptional is its simplicity.',
      mci_philosophy_intro: 'Based on the philosophy of',
      mci_philosophy: '"simplicity is the ultimate sophistication",',
      mci_philosophy_detail: 'all actions that support the method are simple and straightforward. However, this simplicity is the key to its solidity and predictability, providing a truly effective and reliable maintenance management.',

      mci_series_youtube: 'MCI® SERIES ON YOUTUBE:',
      elevate_maintenance: 'ELEVATE YOUR MAINTENANCE TO A NEW LEVEL',
      maximize_availability: 'Are you really maximizing the availability of your plant equipment? How is your productivity? And the cost control and efficiency of maintenance routines?',
      exclusive_playlist_intro: 'In our exclusive playlist,',
      ozorio_rezende: 'Ozório Rezende, Technical Advisor at Pred Engenharia,',
      deep_dive: 'dives deep into the world of industrial maintenance.',
      video_series: 'Get ready for a series of videos that will transform your approach to maintenance. Be prepared for a journey of learning and improvement that will take your maintenance to a new level!',
    
      

      main_content: 'Our innovative solutions are offered through three main areas:',
      main_areas: 'Technical Services, Information Technology, and Industrial Automation.',
      intro_paragraph: 'Each of our areas represents fundamental pillars that allow us to offer a wide range of solutions and expertise, effectively meeting the diverse needs of our clients.',
      technical_services_area: 'In the Technical Services area,',
      technical_services_description: 'we offer predictive inspection, maintenance engineering consulting, and technical training.',
      it_area: 'In Information Technology,',
      it_description: 'we present S3i (Intelligent Industrial Inspection System), a pioneering platform that has used mobile devices in a traceable, intelligent, and integrated manner with systems like IBM Maximo, Infor, Oracle JDE, SAP, and Totvs since 2002.',
      industrial_automation_area: 'In the Industrial Automation area,',
      industrial_automation_description: 'we provide thermal monitoring solutions through infrared thermography to enhance safety, quality, and efficiency in critical processes.',
    
    
      specialized_solutions: 'Specialized in reliability and productivity solutions for O&M areas',
      pred_engineering: 'Pred Engineering specializes in providing solutions for efficient asset and process management. Since 1993, we have been working to ensure the ',
      om_industry_services: 'safety, reliability, and productivity of operations and maintenance (O&M) in the industry and services.',
      
      service_image: "/services4.webp",
      sft_title: "FIXED THERMOGRAPHY SYSTEM",
      sft_solution_description: "A solution by Pred with application engineering and specific tools",
      sft_subtitle: "FIXED THERMOGRAPHY SYSTEM",
      sft_reliable_ally_title: "SFT: Your Reliable Ally for Thermal Control in Industry",
      sft_reliable_ally_description: "In the industry, many abnormalities or operational accidents could be easily avoided or their impacts mitigated if operations were visualized in real-time and comprehensively. Inconveniences such as low luminosity, bad weather, and adverse operational conditions make it difficult to visualize operations, consequently increasing the likelihood of an accident or loss of process control.",
      sft_thermal_imaging_description_1: "Infrared thermography is the most reliable and accurate method for continuous monitoring of operations where temperature is a process variable. The thermographic cameras used by Pred Engenharia have continuous zoom lenses, offering a unique solution for tracking and measuring temperatures at different distances. The zoom lens is fully controlled by specialist software. The fixed thermography systems (SFT) provided by Pred Engenharia are customized to meet the most diverse applications of quantitative and/or qualitative irregularities analysis and evaluations. Its application is present in processes related to support vision and process control.",
      sft_unique_solution_description: "Unique in the market that audits the quality and effectiveness of all types of inspection in real-time through its own methodology.",
      sft_image_1: "/sft/1.webp",
      sft_image_2: "/sft/2.webp",
      support_vision: "Support Vision",
      support_vision_description: "Emphasizing operational safety in equipment and processes, this type of application, classified as low cost and high added value, is essentially qualitative (thermal distribution) and enables real-time and comprehensive supervision.",
      process_control: "Process Control",
      process_control_description_1: "Thermography applied to process control aims to ensure quality, productivity, and/or safety. Applications are qualitative and also quantitative in real-time and comprehensive operations.",
      process_control_description_2: "The solutions from Pred, applied to process control, are always accompanied by application engineering and specific tools. The SFT Portal is one of these tools. It is an intelligent system for monitoring and supervising thermographic trends in equipment and processes in various industrial applications. The SFT Portal is fully configurable and capable of working in the cloud.",
      pred_solutions_description: "Thermography applied to process control aims to ensure quality, productivity, and/or safety. Applications are qualitative and also quantitative in real-time and comprehensive operations.",
      infrared_thermography_description: "Infrared thermography is the most reliable and accurate method for continuous monitoring of operations in locations with adverse viewing conditions.",
      sft_image_3: "/sft/3.webp",
      sft_image_4: "/sft/4.webp",
      why_choose_sft: "Why choose the SFT? Here are some key benefits:",
      precision_reliability: "Precision and Reliability",
      precision_reliability_description: "Infrared thermography is the most reliable method for continuous monitoring of operations where temperature is critical. Our thermographic cameras have continuous zoom lenses, offering precise measurement at different distances.",
      sft_image_5: "/sft/5.webp",
      specialized_engineering: "Specialized Engineering",
      specialized_engineering_description: "Our solutions come with application engineering and specific tools, including the SFT Portal, an intelligent system for monitoring thermographic trends in the cloud.",
      sft_potential: "Unlock your operation's potential with Pred Engenharia's SFT. Rely on infrared thermography to keep your operations safe and efficient, even in the most adverse conditions.",
      sft_more_than_camera_description:'The SFT is much more than just a thermographic camera. It is a complete solution, accompanied by application engineering and specific tools to ensure maximum performance in various industrial applications.',


      service_image3: "/services3.webp",
      s3i_title: "INDUSTRIAL INTERVENTION INTELLIGENT SYSTEM",
      solution_description: "A high-value solution should be more than just a simple e-paper",
      subtitle: "MOBILITY FOR MAINTENANCE",
      image_1: "/s3i/1.webp",
      description_1: "Have you ever imagined optimizing inspection times with intelligence and intuitiveness, while increasing your productivity with safety, precision of information, and performance control of team activities?",
      description_2: "The S3i software is a tool that adheres to operational routines using corporate mobility, ensuring traceability, intelligence, and productivity to inspection, testing, and auditing activities.",
      description_3: "Unique in the market, it performs quality and effectiveness audits of all types of inspections in real-time through a proprietary methodology.",
      traceability_intelligence_title: "Traceability and Intelligence for IBM Maximo, Infor, Oracle JDE, SAP, and Totvs",
      traceability_intelligence_description_1: "S3i stands out with the ability to integrate maintenance with all other information systems used in companies. It has an easy-to-use interface that allows the management of predictive maintenance and the transfer of inspection information to an ERP database. Thus, they are processed, generating new information that allows decision-making about whether or not to repair the inspected equipment.",
      traceability_intelligence_description_2: "As an autonomous system, when integrated with the management systems used by the company, S3i allows greater control over the fulfillment of activities, making the maintenance management system adopted by companies even more robust.",
      image_2: "/s3i/2.webp",
      exclusivities_title: "S3i Software Exclusivities:",
      exclusivity_1: "Traceable and intelligent inspection operations with essential peculiarities for asset management;",
      exclusivity_2: "Use of barcodes, 2D or RFID as a positioner for the inspector in relation to the parts of the Asset to be inspected;",
      exclusivity_3: "Use of intelligent catalogs for recording and handling anomalies (FMEA) in the field;",
      exclusivity_4: "Use of time and motion control tools to monitor inspection quality;",
      exclusivity_5: "Use of post-repair reinspections as a way to validate the effectiveness of scheduled corrective maintenance;",
      exclusivity_6: "Use of a feature that allows the simultaneous recording, handling of failures, and closing of anomalies eliminated at the time of detection;",
      exclusivity_7: "Systemic risk of failure assessments (Perigometer) on each anomaly, using the GUT concept, allowing filtering of those that should actually generate a Note/Order in the ERP/EAM.",
      image_3: "/s3i/3.webp",

      main_content1: 'TECHNICAL INSPECTION SERVICES',
      heading_termografia: 'Unveiling the Invisible with Thermography',
      termografia_description: 'Avoiding failures and accidents in industrial installations is more than a priority; it is a necessity. At Pred Engenharia, we offer specialized inspection services using thermography technology to provide a clear view of the invisible.',
      termografia_technique: 'Thermography is a powerful technique that allows us to map bodies or regions, identifying temperature variations that may indicate potential problems.',
      termografia_market: 'We have been operating in the Industrial Thermography market since 1993, providing high-quality services and products throughout Brazil and other countries.',
      termografia_norms: 'Our services not only comply with the ABNT NBR 15763.2009 technical standard but are also valued by insurance companies, which recognize thermography as an essential practice for accurate risk assessments.',
      termografia_trust: 'Trust Pred Engenharia to ensure the integrity and performance of your industrial equipment and installations. Schedule a thermographic inspection today and discover a new perspective on the safety and efficiency of your operation.',
      heading_vibration_analysis: 'Vibration Analysis: Anticipating Anomalies for Preventive Maintenance',
      vibration_analysis_description: 'At Pred Engenharia, we offer vibration analysis services, a fundamental technique for early detection and diagnosis of anomalies in moving components of industrial equipment.',
      vibration_analysis_technique: 'Using the real operational condition of the equipment, our vibration analysis is based on periodic or continuous measurements of significant parameters. This allows us to anticipate failures, avoid unexpected stops, and unnecessary part replacements, ensuring continuous efficiency and productivity of your operation.',
      heading_ultrasound_inspection: 'Ultrasound Inspection: Precise Detection of Mechanical Defects and Leaks',
      ultrasound_inspection_description: 'Ultrasound inspection is another valuable tool we offer to ensure the integrity of your industrial equipment and installations.',
      ultrasound_inspection_technique: 'This technique allows detection and analysis of mechanical defects in low-speed machines, electrical installations, gas fluid leaks, and much more.',
      ultrasound_inspection_trust: 'Trust Pred Engenharia to keep your equipment in perfect working condition and prevent problems before they occur. Schedule a vibration analysis or ultrasound inspection today and protect the future of your operation.',
      heading_sensitive_inspection: 'Sensitive Inspection: An Essential Complement to Predictive Maintenance',
      sensitive_inspection_description: 'At Pred Engenharia, we understand that integrating sensitive inspections with predictive ones results in valuable insights and high-value results for industrial maintenance.',
      sensitive_inspection_technique: 'Sensitive inspection covers a wide range of failure modes in industrial equipment and installations. Although it is primarily based on the inspector\'s natural senses, it is surprisingly effective in detecting and diagnosing anomalies in middle stages of degradation.',
      sensitive_inspection_advanced_technology: 'Through the use of mobile computing technology, our sensitive inspections are elevated to a new level of intelligence and traceability. This means that each inspection not only identifies potential problems but also provides accurate and actionable data to guide corrective and preventive actions.',
      sensitive_inspection_trust: 'Trust Pred Engenharia to provide comprehensive inspection solutions, integrating both sensitive and predictive approaches to ensure continuous integrity and performance of your industrial assets. Contact us today to learn more about how we can help drive efficiency and reliability in your operation.',
    
      services_maintenance_reliability: 'MAINTENANCE AND RELIABILITY ENGINEERING SERVICES',
      efficient_maintenance_systematization: 'EFFICIENT MAINTENANCE SYSTEMATIZATION: REDUCE YOUR COSTS AND OPTIMIZE CONTROLS',
      efficient_maintenance_text1: 'Efficient maintenance systematics can generate a significant reduction, between 15% and 30%, in fixed and variable costs.',
      efficient_maintenance_text2: '(Enterprise Resource Planning)',
      efficient_maintenance_text3: 'It is time for maintenance to move towards a new way of integrating its assets, reducing costs and improving controls.',
      efficient_maintenance_text4: 'After the era of integrated management systems, such as ERPs (Enterprise Resource Planning), which focused mainly on accounting management, it is now time for maintenance to adopt a new approach to integrate its assets, reducing costs and improving controls.',
      efficient_maintenance_text5: 'This challenge becomes even more crucial for large industries, where maintenance costs are a critical point. These costs represent 20% to 40% of total operating costs, totaling approximately US$ 1 trillion per year globally. The good news is that this value can and should be reduced by up to a third with effective operational asset management, starting with the rational implementation of maintenance management systems.',
      efficient_maintenance_text6: 'With over 30 years of experience, Pred Engenharia offers specialized consultancy to develop and/or implement projects that include:',
      asset_registration: 'Asset registration (physical and functional);',
      maintenance_plan_creation: 'Creation or revision of maintenance plans focused on inspection and lubrication;',
      mobile_computing_integration: 'Integration of mobile computing for maintenance operation and management, going beyond simple "e-paper";',
      technical_list_creation: 'Creation or revision of technical lists of spare parts and inventory strategies;',
      maintenance_staff_optimization: 'Optimization of maintenance staff through time and motion analysis of teams, and training with essential competencies, using our own methodology. This includes evaluating behavioral and cultural aspects;',
      erp_migration_support: 'Support in ERP migration or update, providing master data preparation services for the new version and/or data structure.',
      outdated_solutions_warning: 'Do not let your company be stuck with outdated solutions!',
      discover_pred_services: 'Discover how Pred Engenharia services can drive your operation to new levels of efficiency and success!',
      lubrication_engineering: 'LUBRICATION ENGINEERING: OPTIMIZE YOUR EQUIPMENT PERFORMANCE AND REDUCE FAILURES',
      lubrication_failures: 'Did you know that at least 50% of bearing failures and 60% of gearbox problems are related to lubrication?',
      lubrication_services_intro: 'At Pred Engenharia, we offer five essential engineering services to optimize your lubrication processes and drive technical, managerial, and financial efficiency:',
      maintenance_planning: 'Was your maintenance planning done in the field or in the office?',
      maintenance_planning_field_or_office: 'A well-crafted maintenance plan, done by experienced professionals and on-site, not only increases the effectiveness of operations but also reduces unscheduled downtime, improving your team’s productivity and equipment reliability.',
      maintenance_planning_text: 'Pred Engenharia believes that a good maintenance plan is one that is "doable". For this, it needs to be done in the field and by experienced professionals, following formally defined procedures/guidelines in the maintenance diagnosis, along the four planning lines illustrated above.',
      maintenance_diagnosis: 'MAINTENANCE DIAGNOSIS: REDUCE COSTS AND OPTIMIZE RESULTS',
      maintenance_diagnosis_cost_reduction: 'Did you know that a rational maintenance diagnosis can result in a significant cost reduction, between 15% and 30%?',
      maintenance_diagnosis_text1: 'At Pred Engenharia, our methodology for developing a maintenance diagnosis allows us to cross information from five different sources. This not only provides a comprehensive view of the current situation but also generates precise indicators that guide the necessary measures to optimize your maintenance results.',
      maintenance_diagnosis_text2: 'With Pred Engenharia’s diagnosis, you not only identify areas for improvement but also make data-driven decisions, reducing your operating costs and increasing the efficiency of your maintenance processes.',
      team_training: 'TEAM TRAINING: RAISE THE LEVEL OF COMPETENCE AND EFFICIENCY',
      team_training_text1: 'At Pred Engenharia, we offer tailored closed trainings to your specific needs, always accompanied by efficiency and effectiveness measurement.',
      team_training_text2: 'Additionally, we offer our training focused on the application of advanced maintenance and inspection techniques, including:',
      team_training_list1: 'Maintenance Planning and Control: From the RBM approach to RCM, providing the necessary tools to optimize your maintenance processes',
      team_training_list2: 'Advanced applications of thermography in various areas, such as electrical inspections, structural coatings, rotating and static equipment, and process monitoring.',
      team_training_list3: 'Training of lubrication teams and managers, ensuring that your teams are fully prepared to face daily challenges with confidence and expertise.',
      team_training_text3: 'Investing in team training not only increases operational efficiency but also contributes to the safety, quality, and longevity of your assets.',
      in_company_training: 'MAINTENANCE AUDIT: ENSURE EFFICIENCY AND REDUCE FAILURES',
      specialized_technical_visits: 'At Pred Engenharia, we offer maintenance audit services to ensure that your plans and routines are always up-to-date and aligned with the best industry practices.',
      interactive_training_material: 'The great challenge for maintenance managers is to ensure that critical analysis processes of routines are underway and that the results are used to optimize the maintenance plan. With a regular audit program, you can detect the discontinuity of these processes and take measures to regularize them before they negatively impact your failure rate, interruptions, or maintenance costs.',
      additional_services: 'In addition to identifying areas for improvement, our audits provide valuable insights to enhance your maintenance processes, increasing operational efficiency and reducing the risks of unplanned failures.',
      historia_image1:'1en.webp',
      historia_image2:'2en.webp',
      historia_image3:'3en.webp',
      historia_image4:'4en.webp',
      historia_image5:'5en.webp',
      historia_image21:'21en.webp',
      historia_image31:'31en.webp',
      historia_image41:'41en.webp',
      historia_image51:'51en.webp',
      historia_image61:'61en.webp',

      mci_image1:'mcien.webp',
      mci_image1_mobile:'mcimobileen.webp',
      mci_image2:'mci2en.webp',
      mci_image3:'mci3en.webp',
      mci_image4:'mciseriesen.webp',

      service_man_image1:'1en.webp',
      service_man_image2:'2en.webp',
      service_man_image3:'3en.webp',
      service_man_image4:'4en.webp',
    
    },
    es: {
      years: '',
      years_label: 'AÑOS',
      trusted_partner: 'SU SOCIO DE CONFIANZA',
      industrial_solutions: 'EN SOLUCIONES INDUSTRIALES',
      description_banners: 'Durante tres décadas, hemos sido la elección de confianza para las empresas que buscan soluciones industriales. Ofrecemos inspecciones, consultoría y capacitación especializada. Además, proporcionamos la plataforma inteligente ',
      description_bannerss: 'para inspecciones industriales y soluciones de automatización, incluido el monitoreo térmico a través de termografía infrarroja. Nuestro compromiso es garantizar la seguridad, fiabilidad y productividad de nuestros clientes.',
      
      mci_titile1: 'OBTENGA',
      mci_titile2: 'TODO EL POTENCIAL',
      mci_titile3: 'EN SU MANTENIMIENTO',
      mci_titile4: 'DE MANERA SIMPLES Y ESTRUCTURADA',
      mci_description1: 'Con el método',
      mci_description2: 'de Pred Engenharia, experimentará una perspectiva revolucionaria en la gestión del mantenimiento. A diferencia de otros métodos,',
      mci_description3: 'no solo integra la cadena de valor del mantenimiento, sino que también ofrece atribuciones y resultados claros y auditables. Prepárese para una transformación completa en la forma en que ve y gestiona el mantenimiento.',
      mci_learn_more: 'Más información sobre el método',
      
      pred_engineering_description:"PRED ENGENHARIA se dedica a satisfacer las necesidades de las industrias en el área de mantenimiento predictivo industrial mediante servicios de consultoría. También participamos en investigación y desarrollo de dispositivos, equipos y sistemas enfocados en el mantenimiento predictivo, instrumentación y control.",
      our_journey: "UN POCO DE NUESTRO CAMINO",

      services: 'NUESTROS SERVICIOS',
      engineering: 'SERVICIOS DE INGENIERÍA DE MANTENIMIENTO Y CONFIABILIDAD',
      engineering_desc: 'En Pred Engenharia, ofrecemos una gama completa de servicios de ingeniería diseñados para optimizar el rendimiento de su equipo y procesos industriales. Desde diagnósticos precisos hasta formación especializada, nuestro equipo de expertos está aquí para aumentar la eficiencia, reducir costos y maximizar la productividad de sus operaciones. Descubra cómo nuestros servicios de vanguardia pueden elevar su negocio a nuevos niveles de éxito.',
      learn_more: 'Conozca más sobre el servicio',
      Learn_more_about_us: 'Lea más sobre nosotros',
      inspection: 'SERVICIOS TÉCNICOS DE INSPECCIÓN',
      inspection_desc: 'En Pred Engenharia, ofrecemos más que simples inspecciones; proporcionamos una vista detallada y completa del estado de su equipo industrial. Nuestros servicios técnicos de inspección van más allá de lo obvio, utilizando tecnologías avanzadas y enfoques innovadores para revelar los secretos que pueden estar ocultos dentro de su operación. Ya sea a través de termografía, análisis de vibración, inspección sensitiva u otras técnicas especializadas, estamos aquí para proporcionar conocimientos valiosos que impulsan la eficiencia, la seguridad y la fiabilidad de sus activos.',
      s3i: 'SISTEMA INTELIGENTE DE INTERVENCIÓN INDUSTRIAL',
      s3i_desc: 'Le invitamos a explorar una nueva era en la gestión industrial. Imagine una herramienta que no solo simplifica, sino que también mejora cada paso de su mantenimiento. Haga clic abajo para sumergirse en el mundo del S3i® y descubrir cómo puede elevar sus operaciones a nuevos niveles de excelencia.',
      sft: 'SISTEMA FIJO DE TERMOGRAFÍA',
      sft_desc: 'Imagine tener el control total de las temperaturas críticas en sus operaciones industriales, incluso en condiciones desafiantes. Con el Sistema Fijo de Termografía (SFT) de Pred Engenharia, esta visión se convierte en realidad. ¿Quiere saber cómo el SFT puede transformar su enfoque hacia la seguridad operativa y la eficiencia en la industria? Haga clic abajo para saber más sobre esta herramienta inteligente de monitoreo termográfico y llevar sus operaciones a un nuevo nivel de precisión y fiabilidad.',
    
      know_us: "Conócenos",
      about_description:"PRED Engenharia es un proveedor de soluciones para la gestión de activos y procesos, enfocado en garantizar la seguridad, confiabilidad y productividad de las actividades. Con operaciones en la industria y servicios desde 1993, ofrecemos soluciones innovadoras a través de tres áreas de negocio: Servicios Técnicos, Tecnología de la Información y Automatización Industrial.",
      connect_social: "Conéctate con nosotros en las redes sociales",

      contact_us: "Contáctanos",
      name: "Nombre",
      email: "Correo electrónico",
      phone: "Teléfono",
      whatsapp: "WhatsApp",
      send: "Enviar",
      name_hint: "Introduce tu nombre",
      email_hint: "Ingresa un correo electrónico válido",
      phone_hint: "Ingresa un número de teléfono válido",
      whatsapp_hint: "Ingresa un número de WhatsApp válido",
      error_message: "Por favor, completa este campo correctamente",
      Contact: 'Contacto',

      about_us: 'Sobre Nosotros',
      our_services: 'Nuestros Servicios',
      engineering_maintenance_reliability_services: 'Servicios de Ingeniería de Mantenimiento y Confiabilidad',
      technical_inspection_services: 'Servicios de Inspección Técnica',
      s3i_system: 'S3I - Sistema de Intervención Industrial Inteligente',
      sft_system: 'SFT - Sistema Fijo de Termografía',
      blog: 'Blog',
      vip:'Sala Vip',
      intranet:'Intranet',

      conditioned_maintenance1: 'Mantenimiento',
      conditioned_maintenance2: 'Condicionado a la Inspección',
      discover_mci_method: 'DESCUBRE EL MÉTODO MCI®: LA REVOLUCIÓN EN EL MANTENIMIENTO',


      rbm_notice: 'Asegúrese de que RBM® (Rice & Beans Maintenance) esté realmente implementado antes de invertir tiempo y energía en RCM® (Mantenimiento Centrado en la Confiabilidad). Te explicamos cómo:',
      mci_intro: 'Imagine un enfoque de mantenimiento que no solo integra, sino que también optimiza toda la cadena de valor del mantenimiento,',
      unprecedented_efficiency: 'garantizando una eficiencia y predictibilidad sin precedentes.',
      mci_offer: 'Esto es exactamente lo que ofrece el',
      mci_method: 'Método MCI® (Mantenimiento Condicionado a la Inspección) de Pred Engenharia.',
      mci_cycle_intro: 'En el Método MCI®, el mantenimiento preventivo condicionado y el mantenimiento correctivo planificado están perfectamente integrados en un ciclo cerrado. Este ciclo está impulsado por una herramienta inteligente llamada',
      perigometro: 'PERIGÔMETRO®',
      perigometro_role: ', desarrollada por Pred Engenharia. El',
      perigometro_benefit: 'evalúa con precisión qué intervenciones de mantenimiento son realmente necesarias, proporcionando equilibrio a los recursos y garantizando disponibilidad y predictibilidad al menor costo posible.',
      mci_cycle_benefit: 'Es en este ciclo cerrado que podemos garantizar la disponibilidad y predictibilidad al menor costo posible, ya que trabajamos con una visión mínima de 4 semanas por delante (S+4).',
      mci_exceptional: 'Pero lo que hace que el',
      mci_simplicity: 'Método MCI® sea verdaderamente excepcional es su simplicidad.',
      mci_philosophy_intro: 'Basado en la filosofía de',
      mci_philosophy: '"la simplicidad es la máxima sofisticación",',
      mci_philosophy_detail: 'todas las acciones que sustentan el método son simples y directas. Sin embargo, esta simplicidad es la clave de su solidez y predictibilidad, proporcionando una gestión de mantenimiento verdaderamente efectiva y confiable.',
    

      mci_series_youtube: 'SERIE MCI® EN YOUTUBE:',
      elevate_maintenance: 'ELEVE SU MANTENIMIENTO A UN NUEVO NIVEL',
      maximize_availability: '¿Realmente está maximizando la disponibilidad de los equipos de su planta? ¿Cómo está su productividad? ¿Y el control de costos y la eficiencia de las rutinas de mantenimiento?',
      exclusive_playlist_intro: 'En nuestra lista de reproducción exclusiva,',
      ozorio_rezende: 'Ozório Rezende, Asesor Técnico de Pred Engenharia,',
      deep_dive: 'se sumerge profundamente en el mundo del mantenimiento industrial.',
      video_series: 'Prepárese para una serie de videos que transformarán su enfoque de mantenimiento. ¡Prepárese para un viaje de aprendizaje y mejora que llevará su mantenimiento a un nuevo nivel!',
    
    
      main_content: 'Nuestras soluciones innovadoras se ofrecen a través de tres áreas principales:',
      main_areas: 'Servicios Técnicos, Tecnología de la Información y Automatización Industrial.',
      intro_paragraph: 'Cada una de nuestras áreas representa pilares fundamentales que nos permiten ofrecer una amplia gama de soluciones y experiencia, satisfaciendo eficazmente las diversas necesidades de nuestros clientes.',
      technical_services_area: 'En el área de Servicios Técnicos,',
      technical_services_description: 'ofrecemos inspección predictiva, consultoría en ingeniería de mantenimiento y capacitación técnica.',
      it_area: 'En Tecnología de la Información,',
      it_description: 'presentamos el S3i (Sistema Inteligente de Inspección Industrial), una plataforma pionera que utiliza dispositivos móviles de forma rastreable, inteligente e integrada con sistemas como IBM Maximo, Infor, Oracle JDE, SAP y Totvs desde 2002.',
      industrial_automation_area: 'En el área de Automatización Industrial,',
      industrial_automation_description: 'proporcionamos soluciones de monitoreo térmico mediante termografía infrarroja para mejorar la seguridad, la calidad y la eficiencia en procesos críticos.',
    
      specialized_solutions: 'Especializada en soluciones de confiabilidad y productividad para áreas de O&M',
      pred_engineering: 'Pred Engineering se especializa en ofrecer soluciones para la gestión eficiente de activos y procesos. Desde 1993, hemos estado trabajando para garantizar la ',
      om_industry_services: 'seguridad, confiabilidad y productividad de las operaciones y el mantenimiento (O&M) en la industria y los servicios.',
    

      service_image: "/services4.webp",
      sft_title: "SISTEMA FIJO DE TERMOGRAFÍA",
      sft_solution_description: "Una solución de Pred acompañada de ingeniería de aplicación y herramientas específicas",
      sft_subtitle: "SISTEMA FIJO DE TERMOGRAFÍA",
      sft_reliable_ally_title: "SFT: Su Aliado Confiable para el Control Térmico en la Industria",
      sft_reliable_ally_description: "En la industria, muchas anormalidades o accidentes operativos podrían evitarse fácilmente o mitigarse sus impactos si las operaciones se visualizaran en tiempo real e integralmente. Inconvenientes como la baja luminosidad, el mal tiempo y las condiciones operativas adversas dificultan la visualización de las operaciones y, en consecuencia, aumentan la probabilidad de que ocurra un accidente o se pierda el control de un proceso.",
      sft_thermal_imaging_description_1: "La termografía por infrarrojos es el método más confiable y preciso para el monitoreo continuo de operaciones donde la temperatura es una variable del proceso. Las cámaras termográficas utilizadas por Pred Engenharia tienen lentes con zoom continuo, ofreciendo una solución única para rastrear y medir temperaturas a diferentes distancias. El zoom de la lente está totalmente controlado por un software especializado. Los sistemas de termografía fija (SFT) proporcionados por Pred Engenharia están personalizados para satisfacer las aplicaciones más diversas de análisis y evaluaciones sistémicas de irregularidades cuantitativas y/o cualitativas. Su aplicación está presente en procesos relacionados con la visión de apoyo y el control de procesos.",
      sft_unique_solution_description: "Único en el mercado que realiza auditorías de la calidad y efectividad de todos los tipos de inspección en tiempo real mediante una metodología propia.",
      sft_image_1: "/sft/1.webp",
      sft_image_2: "/sft/2.webp",
      support_vision: "Visión de Apoyo",
      support_vision_description: "Con énfasis en la seguridad operativa en equipos y procesos, este tipo de aplicación, clasificada como de bajo costo y alto valor agregado, es esencialmente cualitativa (distribución térmica) y permite la supervisión en tiempo real e integral.",
      process_control: "Control de Procesos",
      process_control_description_1: "La termografía aplicada al control de procesos tiene como objetivo asegurar la calidad, productividad y/o seguridad. Las aplicaciones son cualitativas y también cuantitativas en operaciones en tiempo real e integral.",
      process_control_description_2: "Las soluciones de Pred, aplicadas al control de procesos, siempre están acompañadas de ingeniería de aplicación y herramientas específicas. El Portal SFT es una de estas herramientas. Es un sistema inteligente para la supervisión y monitoreo de tendencias termográficas de equipos y procesos en diversas aplicaciones industriales. El Portal SFT es totalmente configurable y apto para trabajar en la nube.",
      pred_solutions_description: "La termografía aplicada al control de procesos tiene como objetivo asegurar la calidad, productividad y/o seguridad. Las aplicaciones son cualitativas y también cuantitativas en operaciones en tiempo real e integral.",
      infrared_thermography_description: "La termografía por infrarrojos es el método más confiable y preciso para el monitoreo continuo de operaciones en lugares con condiciones adversas para la visualización.",
      sft_image_3: "/sft/3.webp",
      sft_image_4: "/sft/4.webp",
      why_choose_sft: "¿Por qué elegir el SFT? Aquí hay algunos beneficios clave:",
      precision_reliability: "Precisión y Confiabilidad",
      precision_reliability_description: "La termografía por infrarrojos es el método más confiable para el monitoreo continuo de operaciones donde la temperatura es crítica. Nuestras cámaras termográficas tienen lentes con zoom continuo, ofreciendo mediciones precisas a diferentes distancias.",
      sft_image_5: "/sft/5.webp",
      specialized_engineering: "Ingeniería Especializada",
      specialized_engineering_description: "Nuestras soluciones están acompañadas de ingeniería de aplicación y herramientas específicas, incluido el Portal SFT, un sistema inteligente para monitorear tendencias termográficas en la nube.",
      sft_potential: "Desbloquee el potencial de su operación con el SFT de Pred Engenharia. Confíe en la termografía por infrarrojos para mantener sus operaciones seguras y eficientes, incluso en las condiciones más adversas.",
      sft_more_than_camera_description:'El SFT es mucho más que solo una cámara termográfica. Es una solución completa, acompañada de ingeniería de aplicación y herramientas específicas para garantizar el máximo rendimiento en diversas aplicaciones industriales.',

      service_image3: "/services3.webp",
      s3i_title: "SISTEMA INTELIGENTE DE INTERVENCIÓN INDUSTRIAL",
      solution_description: "Una solución de alto valor agregado debe ser más que un simple e-paper",
      subtitle: "MOVILIDAD PARA EL MANTENIMIENTO",
      image_1: "/s3i/1.webp",
      description_1: "¿Alguna vez has imaginado optimizar los tiempos de inspección con inteligencia e intuición, mientras aumentas tu productividad con seguridad, precisión de la información y control del rendimiento de las actividades del equipo?",
      description_2: "El software S3i es una herramienta que se adhiere a las rutinas operativas utilizando la movilidad corporativa, asegurando trazabilidad, inteligencia y productividad en las actividades de inspección, prueba y auditoría.",
      description_3: "Único en el mercado, realiza auditorías de calidad y efectividad de todo tipo de inspecciones en tiempo real mediante una metodología propia.",
      traceability_intelligence_title: "Trazabilidad e Inteligencia para IBM Maximo, Infor, Oracle JDE, SAP y Totvs",
      traceability_intelligence_description_1: "S3i se destaca por su capacidad para integrar el mantenimiento con todos los demás sistemas de información utilizados en las empresas. Tiene una interfaz fácil de usar que permite la gestión del mantenimiento predictivo y la transferencia de la información de inspección a una base de datos ERP. Así, se procesan, generando nueva información que permite la toma de decisiones sobre la necesidad o no de reparar el equipo inspeccionado.",
      traceability_intelligence_description_2: "Como sistema autónomo, al integrarse con los sistemas de gestión utilizados por la empresa, S3i permite un mayor control sobre el cumplimiento de las actividades, haciendo que el sistema de gestión de mantenimiento adoptado por las empresas sea aún más robusto.",
      image_2: "/s3i/2.webp",
      exclusivities_title: "Exclusividades del Software S3i:",
      exclusivity_1: "Operaciones de inspección rastreables e inteligentes con peculiaridades esenciales para la gestión de activos;",
      exclusivity_2: "Uso de códigos de barras, 2D o RFID como posicionador del inspector en relación con las partes del Activo que se van a inspeccionar;",
      exclusivity_3: "Uso de catálogos inteligentes para el registro y manejo de anomalías (FMEA) en el campo;",
      exclusivity_4: "Uso de herramientas de control de tiempo y movimiento para monitorear la calidad de las inspecciones;",
      exclusivity_5: "Uso de reinspecciones posteriores a la reparación como forma de validar la efectividad de las mantenimientos correctivos programados;",
      exclusivity_6: "Uso de una función que permite el registro simultáneo, manejo de fallas y cierre de anomalías eliminadas en el momento de la detección;",
      exclusivity_7: "Evaluaciones sistémicas del riesgo de falla (Perigómetro) sobre cada anomalía, utilizando el concepto GUT, permitiendo filtrar las que realmente deberían generar una Nota/Orden en el ERP/EAM.",
      image_3: "/s3i/3.webp",

      main_content1: 'SERVICIOS TÉCNICOS DE INSPECCIÓN',
      heading_termografia: 'Descubriendo lo invisible con Termografía',
      termografia_description: 'Evitar fallos y accidentes en instalaciones industriales es más que una prioridad; es una necesidad. En Pred Engenharia ofrecemos servicios especializados de inspección utilizando la tecnología de termografía para proporcionar una visión clara de lo invisible.',
      termografia_technique: 'La termografía es una técnica poderosa que nos permite mapear cuerpos o regiones, identificando variaciones de temperatura que pueden indicar problemas potenciales.',
      termografia_market: 'Operamos en el mercado de la termografía industrial desde 1993, proporcionando servicios y productos de alta calidad en todo Brasil y otros países.',
      termografia_norms: 'Nuestros servicios no solo cumplen con la norma técnica ABNT NBR 15763.2009, sino que también son valorados por las compañías de seguros, que reconocen la termografía como una práctica esencial para evaluaciones precisas de riesgos.',
      termografia_trust: 'Confíe en Pred Engenharia para garantizar la integridad y el rendimiento de sus equipos e instalaciones industriales. Programe una inspección termográfica hoy mismo y descubra una nueva perspectiva sobre la seguridad y eficiencia de su operación.',
      heading_vibration_analysis: 'Análisis de Vibración: Anticipando Anomalías para Mantenimiento Preventivo',
      vibration_analysis_description: 'En Pred Engenharia ofrecemos servicios de análisis de vibración, una técnica fundamental para la detección temprana y diagnóstico de anomalías en componentes móviles de equipos industriales.',
      vibration_analysis_technique: 'Utilizando la condición operativa real del equipo, nuestro análisis de vibración se basa en mediciones periódicas o continuas de parámetros significativos. Esto nos permite anticipar fallos, evitar paradas inesperadas y reemplazos innecesarios de piezas, asegurando así la eficiencia continua y productividad de su operación.',
      heading_ultrasound_inspection: 'Inspección por Ultrasonido: Detección Precisa de Defectos Mecánicos y Fugas',
      ultrasound_inspection_description: 'La inspección por ultrasonido es otra herramienta valiosa que ofrecemos para garantizar la integridad de sus equipos e instalaciones industriales.',
      ultrasound_inspection_technique: 'Esta técnica permite la detección y análisis de defectos mecánicos en máquinas de baja velocidad, instalaciones eléctricas, fugas de fluidos gaseosos y mucho más.',
      ultrasound_inspection_trust: 'Confíe en Pred Engenharia para mantener sus equipos en perfectas condiciones de funcionamiento y evitar problemas antes de que ocurran. Programe hoy mismo un análisis de vibración o inspección por ultrasonido y proteja el futuro de su operación.',
      heading_sensitive_inspection: 'Inspección Sensitiva: Un Complemento Esencial para el Mantenimiento Predictivo',
      sensitive_inspection_description: 'En Pred Engenharia entendemos que integrar inspecciones sensitivas con las predictivas resulta en ideas valiosas y resultados de alto valor agregado para el mantenimiento industrial.',
      sensitive_inspection_technique: 'La inspección sensitiva abarca una amplia gama de modos de fallo en equipos e instalaciones industriales. Aunque se basa principalmente en los sentidos naturales del inspector, es sorprendentemente efectiva en la detección y diagnóstico de anomalías en etapas intermedias de degradación.',
      sensitive_inspection_advanced_technology: 'Mediante el uso de tecnología de computación móvil, nuestras inspecciones sensitivas se elevan a un nuevo nivel de inteligencia y rastreabilidad. Esto significa que cada inspección no solo identifica problemas potenciales, sino que también proporciona datos precisos y accionables para guiar acciones correctivas y preventivas.',
      sensitive_inspection_trust: 'Confíe en Pred Engenharia para proporcionar soluciones de inspección integrales, integrando enfoques sensitivos y predictivos para garantizar la integridad y rendimiento continuo de sus activos industriales. Contáctenos hoy mismo para obtener más información sobre cómo podemos ayudar a impulsar la eficiencia y confiabilidad en su operación.',
    
      services_maintenance_reliability: 'SERVICIOS DE INGENIERÍA DE MANTENIMIENTO Y CONFIABILIDAD',
      efficient_maintenance_systematization: 'SISTEMATIZACIÓN EFICIENTE DEL MANTENIMIENTO: REDUZCA SUS COSTOS Y OPTIMICE LOS CONTROLES',
      efficient_maintenance_text1: 'La sistemática eficiente del mantenimiento puede generar una reducción significativa, entre el 15% y el 30%, en los costos fijos y variables.',
      efficient_maintenance_text2: '(Planificación de Recursos Empresariales)',
      efficient_maintenance_text3: 'Es hora de que el mantenimiento adopte una nueva forma de integrar sus activos, reduciendo costos y mejorando controles.',
      efficient_maintenance_text4: 'Después de la era de los sistemas de gestión integrada, como los ERPs (Planificación de Recursos Empresariales), que se concentraban principalmente en la gestión contable, ha llegado el momento de que el mantenimiento adopte un nuevo enfoque para integrar sus activos, reduciendo costos y mejorando controles.',
      efficient_maintenance_text5: 'Este desafío se vuelve aún más crucial para las grandes industrias, donde los costos de mantenimiento son un punto crítico. Estos costos representan del 20% al 40% del total de los costos operativos, totalizando aproximadamente US$ 1 billón al año a nivel mundial. La buena noticia es que este valor puede y debe reducirse en hasta un tercio con una gestión eficaz de los activos operacionales, comenzando por la implementación racional de sistemas de gestión de mantenimiento.',
      efficient_maintenance_text6: 'Con más de 30 años de experiencia, Pred Engenharia ofrece consultoría especializada para desarrollar y/o implementar proyectos que incluyen:',
      asset_registration: 'Registro de activos (físicos y funcionales);',
      maintenance_plan_creation: 'Creación o revisión de planes de mantenimiento con enfoque en inspección y lubricación;',
      mobile_computing_integration: 'Integración de computación móvil para la operacionalización y gestión del mantenimiento, yendo más allá del simple "e-paper";',
      technical_list_creation: 'Creación o revisión de listas técnicas de repuestos y estrategias de inventario;',
      maintenance_staff_optimization: 'Optimización del personal de mantenimiento mediante análisis de tiempos y movimientos de los equipos, y capacitación con competencias esenciales, utilizando una metodología propia. Esto incluye la evaluación de aspectos comportamentales y culturales;',
      erp_migration_support: 'Soporte en la migración o actualización de ERP, proporcionando servicios de preparación de los datos maestros del legado para la nueva versión y/o estructura de datos.',
      outdated_solutions_warning: '¡No deje su empresa atrapada en soluciones obsoletas!',
      discover_pred_services: '¡Descubra cómo los servicios de Pred Engenharia pueden impulsar su operación a nuevos niveles de eficiencia y éxito!',
      lubrication_engineering: 'INGENIERÍA DE LUBRICACIÓN: OPTIMICE EL RENDIMIENTO DE SUS EQUIPOS Y REDUZCA FALLAS',
      lubrication_failures: '¿Sabía que al menos el 50% de las fallas en rodamientos y el 60% de los problemas en reductores de engranajes están relacionados con la lubricación?',
      lubrication_services_intro: 'En Pred Engenharia, ofrecemos cinco servicios esenciales de ingeniería para optimizar sus procesos de lubricación e impulsar la eficiencia técnica, gerencial y financiera:',
      maintenance_planning: '¿Su planificación de mantenimiento se hizo en el campo o en la oficina?',
      maintenance_planning_field_or_office: 'Un plan de mantenimiento bien elaborado, realizado por profesionales experimentados y en el sitio, no solo aumenta la eficacia de las operaciones, sino que también reduce el tiempo de inactividad no programado, mejorando la productividad de su equipo y la confiabilidad de los equipos.',
      maintenance_planning_text: 'Pred Engenharia cree que un buen plan de mantenimiento es aquel que es "cumplible". Para ello, debe hacerse en el campo y por profesionales experimentados, siguiendo procedimientos/directrices formalmente definidos en el diagnóstico del mantenimiento, sobre las cuatro líneas de planificación ilustradas anteriormente.',
      maintenance_diagnosis: 'DIAGNÓSTICO DEL MANTENIMIENTO: REDUZCA COSTOS Y OPTIMICE RESULTADOS',
      maintenance_diagnosis_cost_reduction: '¿Sabía que un diagnóstico racional de su mantenimiento puede resultar en una reducción significativa de costos, entre el 15% y el 30%?',
      maintenance_diagnosis_text1: 'En Pred Engenharia, nuestra metodología para la elaboración del diagnóstico del mantenimiento permite cruzar información de cinco fuentes diferentes. Esto no solo proporciona una visión integral de la situación actual, sino que también genera indicadores precisos que dirigen las medidas necesarias para optimizar los resultados de su mantenimiento.',
      maintenance_diagnosis_text2: 'Con el diagnóstico de Pred Engenharia, no solo identifica áreas de mejora, sino que también toma decisiones basadas en datos concretos, reduciendo sus costos operativos y aumentando la eficiencia de sus procesos de mantenimiento.',
      team_training: 'CAPACITACIÓN DE EQUIPOS: ELEVE EL NIVEL DE COMPETENCIA Y EFICIENCIA',
      team_training_text1: 'En Pred Engenharia, ofrecemos entrenamientos cerrados, adaptados a sus necesidades específicas, siempre acompañados de medición de eficiencia y eficacia.',
      team_training_text2: 'Además, ofrecemos nuestros entrenamientos enfocados en la aplicación de técnicas avanzadas de mantenimiento e inspección, incluyendo:',
      team_training_list1: 'Planificación y Control del Mantenimiento: Desde el enfoque RBM hasta el RCM, proporcionando las herramientas necesarias para optimizar sus procesos de mantenimiento',
      team_training_list2: 'Aplicaciones avanzadas de la termografía en diversas áreas, como inspecciones eléctricas, revestimientos estructurales, equipos rotativos y estáticos, y monitoreo de procesos.',
      team_training_list3: 'Capacitación de equipos de lubricación y gestores, asegurando que sus equipos estén completamente preparados para enfrentar los desafíos del día a día con confianza y experiencia.',
      team_training_text3: 'Invertir en la capacitación de sus equipos no solo aumenta la eficiencia operativa, sino que también contribuye a la seguridad, calidad y longevidad de sus activos.',
      in_company_training: 'AUDITORÍA DEL MANTENIMIENTO: GARANTICE EFICIENCIA Y REDUZCA FALLAS',
      specialized_technical_visits: 'En Pred Engenharia, ofrecemos servicios de auditoría del mantenimiento para garantizar que sus planes y rutinas estén siempre actualizados y alineados con las mejores prácticas del sector.',
      interactive_training_material: 'El gran desafío de los gestores de mantenimiento es garantizar que los procesos de análisis crítico sobre las rutinas estén en marcha y que los resultados se utilicen para optimizar el plan de mantenimiento. Con un programa regular de auditorías, puede detectar la discontinuidad de estos procesos y tomar medidas para regularizarlos antes de que impacten negativamente en su tasa de fallas, interrupciones o costos de mantenimiento.',
      additional_services: 'Además de identificar áreas de mejora, nuestras auditorías proporcionan valiosas ideas para mejorar sus procesos de mantenimiento, aumentando la eficiencia operativa y reduciendo los riesgos de fallas no planificadas.',
      historia_image1:'1es.webp',
      historia_image2:'2es.webp',
      historia_image3:'3es.webp',
      historia_image4:'4es.webp',
      historia_image5:'5es.webp',
      historia_image21:'21es.webp',
      historia_image31:'31es.webp',
      historia_image41:'41es.webp',
      historia_image51:'51es.webp',
      historia_image61:'61es.webp',

      mci_image1:'mcies.webp',
      mci_image1_mobile:'mcimobilees.webp',
      mci_image2:'mci2es.webp',
      mci_image3:'mci3es.webp',
      mci_image4:'mciserieses.webp',

      service_man_image1:'1es.webp',
      service_man_image2:'2es.webp',
      service_man_image3:'3es.webp',
      service_man_image4:'4es.webp',
    
      
    }
    
  }
}))
